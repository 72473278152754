import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { T } from "@tolgee/react";
import { useSignInContext } from "../signInProvider";
import { Message, TextWithLinksProps } from "../../lib/types";
import TextWithLinks from "./textWithLinks";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { LoaderCircle } from "lucide-react";
import { signIn } from "next-auth/react";
import { onboardingPath } from "../../lib/utils";

export default function EmailForm({
    textsWithLinks,
}: {
    textsWithLinks: TextWithLinksProps;
}) {
    const [email, setEmail] = useState("");
    const [error, setError] = useState<Message>(null);

    const { loading, setLoading } = useSignInContext();

    const submitHandler = async (e: any) => {
        e.preventDefault();

        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!email) {
            setError({
                keyName: "auth-card.errors.email.required",
                defaultValue: "שדה המייל חובה",
            });
            return;
        }

        if (!emailRegex.test(email)) {
            setError({
                keyName: "auth-card.errors.email.invalid",
                defaultValue: "כתובת מייל לא חוקית",
            });
            return;
        }

        setError(null);
        setLoading(true);

        try {
            await signIn("resend", {
                email,
                redirectTo: onboardingPath,
            });
        } catch (err) {
            setError({
                keyName: "auth-card.errors.magic-link-sign-in.failed",
                defaultValue: "שגיאה בהתחברות",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <form onSubmit={submitHandler} className="flex flex-col gap-y-6">
                <div className="">
                    <Label htmlFor="email">
                        <T
                            keyName="auth-card.inputs.email.label"
                            defaultValue="מייל לאימות"
                        />
                    </Label>

                    <Input
                        id="email"
                        name="email"
                        type="email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setError(null);
                        }}
                        disabled={loading}
                        required
                    />
                    {error && (
                        <p className="text-red-600 text-sm">
                            <T
                                keyName={error.keyName}
                                defaultValue={error.defaultValue}
                            />
                        </p>
                    )}
                </div>

                <TextWithLinks {...textsWithLinks} />

                <Button
                    size="sm"
                    type="submit"
                    className="w-full"
                    disabled={loading}
                >
                    {loading ? (
                        <LoaderCircle className="size-4 animate-spin" />
                    ) : (
                        <T
                            keyName="auth-card.submit-btn"
                            defaultValue="התחברות"
                        />
                    )}
                </Button>
            </form>
        </>
    );
}
