import { Button } from "@/components/ui/button";
import { T } from "@tolgee/react";
import { FcGoogle } from "react-icons/fc";
import { useSignInContext } from "../signInProvider";
import { signIn } from "next-auth/react";

export default function SignInWithGoogle() {
    const { loading, setLoading } = useSignInContext();

    const signInWithGoogle = async () => {
        setLoading(true);
        signIn("google", {
            redirectTo: "/home",
        });
    };

    return (
        <>
            <Button
                onClick={() => signInWithGoogle()}
                variant="outline"
                className="w-full"
                disabled={loading}
            >
                <FcGoogle className="size-5 me-2" />
                <T
                    keyName="auth-card.social-button.google"
                    defaultValue="המשיכו עם גוגל"
                />
            </Button>

            <div className="relative">
                <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm leading-6">
                    <span className="bg-white px-6">
                        <T
                            keyName="auth-card.auth-separator"
                            defaultValue="או"
                        />
                    </span>
                </div>
            </div>
        </>
    );
}
