"use client";

import { PlatformAuthCardProps } from "../lib/types";
import { Card, CardContent } from "@/components/ui/card";
import AuthHeader from "./ui/header";
import SignInWithGoogle from "./ui/googleButton";
import { cn } from "@/lib/utils";
import EmailForm from "./ui/email";

export default function SignIn({
    className,
    textsWithLinks,
}: PlatformAuthCardProps) {
    return (
        <Card
            className={cn(
                "w-full h-full p-4 md:p-6 lg:p-8 border-none shadow-none",
                className
            )}
        >
            <AuthHeader />

            <CardContent className="space-y-6 px-0 pb-0 pt-6">
                <SignInWithGoogle />

                <EmailForm textsWithLinks={textsWithLinks} />
            </CardContent>
        </Card>
    );
}
