import { Checkbox } from "@/components/ui/checkbox";
import { T } from "@tolgee/react";
import { TextWithLinksProps } from "../../lib/types";

export default function TextWithLinks({
    checkbox,
    forceCheckboxChecked,
    text,
    links,
    keyName,
}: TextWithLinksProps) {
    // Create a regular expression to find phrases in the text
    const regex = new RegExp(
        `(${links
            .map((link) =>
                link.phrase.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")
            )
            .join("|")})`,
        "gi"
    );

    // Split the text into segments of text and link phrases
    let segments = text.split(regex);

    // Filter out empty segments or segments that are only whitespace
    segments = segments.filter((segment) => segment.trim() !== "");

    return (
        <div className="flex flex-row gap-2 w-full items-center">
            {checkbox && (
                <Checkbox
                    name="disclaimerCheckbox"
                    id="disclaimerCheckbox"
                    required={forceCheckboxChecked}
                />
            )}
            <p className="text-sm text-gray-600">
                {segments.map((segment, index) => {
                    const link = links.find(
                        (link) =>
                            link.phrase.toLowerCase() === segment.toLowerCase()
                    );
                    if (link) {
                        const content = keyName ? (
                            <T
                                keyName={`${keyName}.link.${index}`}
                                defaultValue={segment}
                            />
                        ) : (
                            segment
                        );
                        return link.type === "link" ? (
                            <a
                                key={index}
                                href={link.href}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="font-medium text-gray-700 hover:underline"
                            >
                                {content}
                            </a>
                        ) : (
                            <button
                                key={index}
                                type="button"
                                className="font-medium text-gray-700 hover:underline"
                                onClick={link.onClick}
                            >
                                {content}
                            </button>
                        );
                    }
                    // For non-link segments
                    const content = keyName ? (
                        <T
                            keyName={`${keyName}.segment.${index}`}
                            defaultValue={segment}
                        />
                    ) : (
                        segment
                    );
                    return <span key={`segment-${index}`}>{content} </span>;
                })}
            </p>
        </div>
    );
}
