import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { T } from "@tolgee/react";

export default function AuthHeader() {
    return (
        <CardHeader className="p-0 text-center">
            <CardTitle>
                <T keyName="auth-card.signin.title" defaultValue="התחברות" />
            </CardTitle>
            <CardDescription>
                <T
                    keyName="auth-card.signin.description"
                    defaultValue="המשיכו עם גוגל או הזינו כתובת מייל לאימות"
                />
            </CardDescription>
        </CardHeader>
    );
}
