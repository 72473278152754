"use client";

import {
    createContext,
    useContext,
    useState,
    ReactNode,
    Context,
    Dispatch,
    SetStateAction,
} from "react";

interface SignInProviderProps {
    children: ReactNode;
}

interface SignInContextType {
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

const SignInContext = createContext<SignInContextType | undefined>(undefined);

export const useSignInContext = () => {
    const context = useContext(
        SignInContext as Context<SignInContextType | undefined>
    );
    if (!context) {
        throw new Error("useSignInContext must be used within a SignInContext");
    }
    return context;
};

export default function SignInProvider({
    children,
}: SignInProviderProps): JSX.Element {
    const [loading, setLoading] = useState(false);

    const value: SignInContextType = {
        loading,
        setLoading,
    };

    return (
        <SignInContext.Provider value={value as any}>
            {children}
        </SignInContext.Provider>
    );
}
